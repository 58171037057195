import {
    ButtonBase,
    Card,
    CardActionArea,
    Stack,
    Typography
} from '@mui/material'
import {
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { type GraphQlMicrostep } from '../../../../../graphql/generated/autogenerated'
import MicrostepNewLabel from '../../MicrostepNewLabel'
import { MicrostepState } from '../index'
import { type IconName } from '@fortawesome/fontawesome-common-types'

export interface MicrostepCardDataProps {
    microstep: GraphQlMicrostep
    state: MicrostepState
    isChecked?: boolean
    disabled?: boolean
    searchStr?: string
    disableDialog?: boolean
    elevation?: number
    fillSpace?: boolean
    customTitle?: string
    onChecked?: () => void
    onAdded?: () => void
    onUnchecked?: () => void
    onOpenDialog?: () => void
}

class VariantConfig {
    variant: string
    icon: IconName
    btnColor:
        | 'inherit'
        | 'action'
        | 'disabled'
        | 'primary'
        | 'secondary'
        | 'accent'
        | 'error'
        | 'info'
        | 'success'
    testId: string
}

const messages = defineMessages({
    checkInto: {
        defaultMessage: 'Check into the microstep "{microstep}"',
        description:
            'button text describing the user is checking into the specific microstep by its name'
    },
    check: {
        defaultMessage: 'check',
        description: ''
    },
    collapseMicrostep: {
        defaultMessage: 'Collapse microstep details for "{microstep}"',
        description:
            'button text describing the user is collapsing the microstep details'
    },
    expandMicrostep: {
        defaultMessage: 'Expand microstep details for "{microstep}"',
        description:
            'button text describing the user is expanding the microstep details'
    },
    openMicrostepDetailDialog: {
        defaultMessage: 'open microstep details dialog of "{microstep}"',
        description:
            'button text describing the user is expanding the microstep details'
    }
})

const MicrostepCardData: React.FC<MicrostepCardDataProps> = ({
    microstep,
    state,
    isChecked,
    disabled,
    searchStr,
    disableDialog,
    elevation,
    fillSpace,
    customTitle,
    onChecked,
    onAdded,
    onUnchecked,
    onOpenDialog
}) => {
    const theme = useTheme()
    const { locale, formatMessage } = useIntl()
    const [checked, setChecked] = useState<boolean>(isChecked)
    const [microstepTitle, setMicrostepTitle] = useState<any>(microstep.title)

    const getVariantConfig = (): VariantConfig => {
        const toggleVariant: VariantConfig = {
            variant: 'contained',
            icon: checked ? 'check' : 'add',
            btnColor: checked ? 'accent' : 'secondary',
            testId: `${microstep.id}-add`
        }
        const checkInVariant: VariantConfig = {
            variant: checked ? 'contained' : 'outlined',
            icon: checked ? 'check' : undefined,
            btnColor: 'accent',
            testId: `${microstep.id}-check-in`
        }
        switch (state) {
            case MicrostepState.Toggle:
                return toggleVariant
            case MicrostepState.CheckIn:
                return checkInVariant
            default:
                return toggleVariant
        }
    }
    const variantConfig: VariantConfig = getVariantConfig()

    const journey = React.useMemo(() => {
        return (
            microstep?.journeys?.length > 0 && microstep?.journeys[0]?.shortName
        )
    }, [microstep?.journeys])

    const clickHandler = useCallback(() => {
        if (checked) {
            setChecked(false)
            onUnchecked()
        } else {
            setChecked(true)
            if (variantConfig.testId === `${microstep.id}-add`) {
                onAdded()
            } else {
                onChecked()
            }
        }
    }, [
        checked,
        setChecked,
        onChecked,
        onUnchecked,
        microstep.id,
        onAdded,
        variantConfig.testId
    ])

    const openDialogHandler = useCallback(
        ($event) => {
            $event.stopPropagation()
            if (state === MicrostepState.Summary) {
                return
            }
            onOpenDialog()
        },
        [state, onOpenDialog]
    )

    useEffect(() => {
        setChecked(isChecked)
    }, [isChecked, setChecked])

    const canRenderCheck =
        state === MicrostepState.Toggle || state === MicrostepState.CheckIn

    useEffect(() => {
        const splitStart = searchStr
            ? microstep.title.toLowerCase().indexOf(searchStr.toLowerCase())
            : -1
        if (searchStr && splitStart >= 0) {
            setMicrostepTitle(
                <>
                    {microstep.title.slice(0, splitStart)}
                    <span
                        style={{
                            fontWeight: 700
                        }}
                    >
                        {microstep.title.slice(
                            splitStart,
                            splitStart + searchStr.length
                        )}
                    </span>
                    {microstep.title.slice(splitStart + searchStr?.length)}
                </>
            )
        } else {
            setMicrostepTitle(microstep.title)
        }
    }, [microstep.title, setMicrostepTitle, searchStr])

    return (
        <Card
            elevation={elevation ? elevation : 1}
            sx={{
                cursor:
                    state === MicrostepState.Summary ? 'initial' : 'pointer',
                pointerEvents:
                    state === MicrostepState.Summary || disabled
                        ? 'none'
                        : 'initial',
                ...(fillSpace && {
                    height: '100%'
                })
            }}
        >
            <CardActionArea onClick={clickHandler}>
                <Stack
                    data-testid="microstep-card-data"
                    alignItems="center"
                    direction="row"
                    p={2}
                    gap={2}
                >
                    {canRenderCheck && (
                        <Stack>
                            <IconButton
                                variant={variantConfig.variant}
                                color={variantConfig.btnColor}
                                disabled={disabled}
                                aria-label={formatMessage(messages.checkInto, {
                                    microstep: microstep.title
                                })}
                                data-testid={variantConfig.testId}
                            >
                                {variantConfig.icon && (
                                    <LeafIcon icon={variantConfig.icon} />
                                )}
                            </IconButton>
                        </Stack>
                    )}
                    <ButtonBase
                        sx={{ width: '100%', textAlign: 'initial' }}
                        disableRipple={true}
                        disabled={disabled}
                    >
                        <Stack
                            width="100%"
                            direction="row"
                            alignItems="center"
                            gap={1}
                        >
                            <Stack width="100%" gap={0.5}>
                                {journey && (
                                    <Stack direction="row" alignItems="center">
                                        <Typography
                                            variant="overline"
                                            color="accent.main"
                                        >
                                            {journey.toLocaleUpperCase(
                                                locale
                                            ) || ''}
                                        </Typography>
                                        <MicrostepNewLabel
                                            microstep={microstep}
                                        ></MicrostepNewLabel>
                                    </Stack>
                                )}
                                <Typography
                                    variant="body1"
                                    color={'text.primary'}
                                >
                                    {customTitle ? customTitle : microstepTitle}
                                </Typography>
                            </Stack>
                            {state !== MicrostepState.Summary &&
                                !disableDialog && (
                                    <IconButton
                                        onClick={openDialogHandler}
                                        aria-label={formatMessage(
                                            messages.openMicrostepDetailDialog,
                                            {
                                                microstep: microstep.title
                                            }
                                        )}
                                    >
                                        <LeafIcon icon={'ellipsis'} />
                                    </IconButton>
                                )}
                        </Stack>
                    </ButtonBase>
                </Stack>
            </CardActionArea>
        </Card>
    )
}

export default React.memo(MicrostepCardData)
