import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    useMediaQuery
} from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { MicrostepState } from '../MicrostepCard'
import {
    type GraphQlMicrostep,
    type MicrostepRemovalReason,
    type MicrostepUserProgress,
    useGetMicrostepCheckinProgressV2Query
} from '../../../../graphql/generated/autogenerated'
import {
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import MicrostepDialogConfirm from './MicrostepDialogConfirm'
import MicrostepDialogDetails from './MicrostepDialogDetails'

const messages = defineMessages({
    chevronLeftIcon: {
        defaultMessage: 'Chevron left icon',
        description: 'Aria label for the chevron left icon'
    },
    shareIcon: {
        defaultMessage: 'Share icon',
        description: 'Aria label for the share icon'
    },
    closeIcon: {
        defaultMessage: 'Close icon',
        description: 'Aria label for the close icon'
    }
})

interface MicrostepDialogProps {
    isOpen: boolean
    microstep: GraphQlMicrostep
    state: MicrostepState
    isChecked: boolean
    shareUrl?: string
    disableRemove: boolean
    onClose: () => void
    onChecked: () => void
    onUnchecked: () => void
    onAdded: () => void
    onRemoved: (reason: MicrostepRemovalReason) => void
    onShareClicked: () => void
}

export enum MicrostepDialogView {
    Detail,
    Confirm
}

const MicrostepDialog: React.FC<MicrostepDialogProps> = ({
    isOpen,
    microstep,
    state,
    isChecked,
    shareUrl,
    disableRemove,
    onClose,
    onChecked,
    onUnchecked,
    onAdded,
    onRemoved,
    onShareClicked
}) => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const [view, setView] = useState<number>(MicrostepDialogView.Detail)
    const [linkCopied, setLinkCopied] = useState<boolean>(false)
    const [microstepRemoved, setMicrostepRemoved] = useState<boolean>(false)
    const [microstepRemovalReason, setMicrostepRemovalReason] =
        useState<MicrostepRemovalReason>()
    const [microstepProgress, setMicrostepProgress] =
        useState<MicrostepUserProgress>(undefined)

    const {
        canRenderCloseIcon,
        canRenderStepBackIcon,
        canRenderMobileShareIcon
    } = useMemo(() => {
        return {
            canRenderCloseIcon:
                (!isMobile && view === MicrostepDialogView.Detail) ||
                microstepRemoved,
            canRenderStepBackIcon:
                (view === MicrostepDialogView.Confirm || isMobile) &&
                !microstepRemoved,
            canRenderMobileShareIcon:
                shareUrl && isMobile && view === MicrostepDialogView.Detail
        }
    }, [isMobile, view, microstepRemoved, shareUrl])

    const [dates] = useState({
        dateMinusSevenDays: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10),
        today: new Date(Date.now()).toISOString().slice(0, 10)
    })

    const { data: progressData } = useGetMicrostepCheckinProgressV2Query({
        variables: {
            microstepid: microstep.id,
            from: dates.dateMinusSevenDays,
            to: dates.today
        }
    })

    const linkCopiedHandler = useCallback(() => {
        navigator.clipboard.writeText(shareUrl)
        setLinkCopied(true)
        onShareClicked()
    }, [shareUrl, onShareClicked])

    const onStepBackHandler = useCallback(() => {
        setLinkCopied(false)
        view === MicrostepDialogView.Detail
            ? onClose()
            : setView(MicrostepDialogView.Detail)
    }, [view, setView, onClose])

    const onRemovedHandler = useCallback(
        (reason: MicrostepRemovalReason) => {
            setMicrostepRemoved(true)
            setMicrostepRemovalReason(reason)
        },
        [setMicrostepRemoved]
    )

    const onPreventRemovalHandler = useCallback(() => {
        setMicrostepRemoved(false)
    }, [setMicrostepRemoved])

    const onCloseHandler = useCallback(() => {
        if (microstepRemoved) {
            onRemoved(microstepRemovalReason)
        }
        onClose()
    }, [onRemoved, onClose, microstepRemoved, microstepRemovalReason])

    useEffect(() => {
        if (progressData) {
            setMicrostepProgress(
                progressData.today.getMicrostepCheckinProgressV2
            )
        }
    }, [progressData])

    return (
        <Dialog
            data-testid="microstep-dialog-2"
            open={isOpen}
            fullScreen={isMobile}
            onClose={onCloseHandler}
            // PaperProps={{
            //     elevation: 18,
            //     style: {
            //         borderRadius: isMobile ? 0 : theme.spacing(1),
            //         minWidth: isMobile ? 'initial' : theme.spacing(84.75),
            //         maxWidth: isMobile ? 'initial' : theme.spacing(84.75),
            //         background: isMobile
            //             ? 'linear-gradient(128.32deg, #FDFDFD 3.91%, #EBF3F4 94.19%)'
            //             : theme.palette.primary.contrastText
            //     }
            // }}
            // BackdropProps={{
            //     style: { backgroundColor: alpha(theme.palette.grey[900], 0.24) }
            // }}
        >
            <DialogActions
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent:
                        view === 0 && !isMobile ? 'flex-end' : 'space-between',
                    px: theme.spacing(4),
                    pt: theme.spacing(4),
                    pb: theme.spacing(3),
                    [theme.breakpoints.down('sm')]: {
                        padding: theme.spacing(2)
                    }
                }}
            >
                {canRenderStepBackIcon && (
                    <IconButton
                        ariaLabel={formatMessage(messages.chevronLeftIcon)}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={onStepBackHandler}
                    >
                        <LeafIcon icon={'chevron-left'} />
                    </IconButton>
                )}
                {canRenderMobileShareIcon && (
                    <IconButton
                        ariaLabel={formatMessage(messages.shareIcon)}
                        color={'secondary'}
                        variant={'info'}
                        onClick={linkCopiedHandler}
                    >
                        <LeafIcon icon={'share-nodes'} />
                    </IconButton>
                )}
                {canRenderCloseIcon && (
                    <IconButton
                        ariaLabel={formatMessage(messages.closeIcon)}
                        color={'secondary'}
                        variant={'contained'}
                        onClick={onCloseHandler}
                    >
                        <LeafIcon icon={'xmark'} />
                    </IconButton>
                )}
            </DialogActions>
            <DialogContent
                sx={{
                    pt: 0,
                    px: theme.spacing(4),
                    pb: theme.spacing(4),
                    display: 'flex',
                    flexDirection: 'column',
                    [theme.breakpoints.down('sm')]: {
                        pt: theme.spacing(8),
                        px: theme.spacing(2),
                        pb: theme.spacing(2)
                    }
                }}
            >
                <Box
                    width={'100%'}
                    height={'100%'}
                    display={'flex'}
                    flexDirection={'column'}
                    pb={theme.spacing(3)}
                    sx={{
                        px: theme.spacing(4),
                        [theme.breakpoints.down('sm')]: {
                            px: theme.spacing(1)
                        }
                    }}
                >
                    {view === MicrostepDialogView.Detail && (
                        <MicrostepDialogDetails
                            microstep={microstep}
                            microstepProgress={microstepProgress}
                            state={state}
                            isChecked={isChecked}
                            linkCopied={linkCopied}
                            shareUrl={shareUrl}
                            onAdded={onAdded}
                            onChecked={onChecked}
                            onUnchecked={onUnchecked}
                            onClose={onCloseHandler}
                            onViewChange={setView}
                            onLinkCopied={linkCopiedHandler}
                            disableRemove={disableRemove}
                        />
                    )}
                    {view === MicrostepDialogView.Confirm && (
                        <MicrostepDialogConfirm
                            microstep={microstep}
                            onClose={onCloseHandler}
                            onRemove={onRemovedHandler}
                            onPreventRemoval={onPreventRemovalHandler}
                        />
                    )}
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default MicrostepDialog
