import { Box } from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'
import {
    type GraphQlMicrostep,
    type MicrostepRemovalReason
} from '../../../../graphql/generated/autogenerated'
import MicrostepDialog from '../MicrostepDialog'
import MicrostepCardData from './MicrostepCardData'

export enum MicrostepState {
    CheckIn,
    Toggle,
    Preview,
    Summary
}

export enum MicrostepViewState {
    Card,
    Dialog
}

export type MicrostepCardProps = {
    microstep: GraphQlMicrostep
    state: MicrostepState
    isChecked?: boolean
    searchStr?: string
    disabled?: boolean
    openOnMount?: boolean
    shareUrl?: string
    disableRemove?: boolean
    disableDialog?: boolean
    elevation?: number
    fillSpace?: boolean
    customTitle?: string
    onChecked?: (viewState: MicrostepViewState) => void
    onUnchecked?: (viewState: MicrostepViewState) => void
    onRemoved?: (reason: MicrostepRemovalReason) => void
    onAdded?: (viewState: MicrostepViewState) => void
    onDialogOpen?: () => void
    onDialogClose?: () => void
    onShareClicked?: () => void
}

const MicrostepCard: React.FC<MicrostepCardProps> = ({
    microstep,
    state,
    isChecked,
    searchStr,
    disabled,
    openOnMount,
    shareUrl,
    disableRemove,
    disableDialog,
    elevation,
    fillSpace,
    customTitle,
    onChecked,
    onUnchecked,
    onRemoved,
    onAdded,
    onDialogOpen,
    onDialogClose,
    onShareClicked
}) => {
    const [checked, setChecked] = useState<boolean>(isChecked)
    const [openDialog, setOpenDialog] = useState<boolean>(false)
    const [microstepViewState, setMicrostepViewState] =
        useState<MicrostepViewState>(MicrostepViewState.Card)

    const onCheckHandler = useCallback(() => {
        setChecked(true)
        onChecked?.(microstepViewState)
    }, [setChecked, microstepViewState, onChecked])

    const onUncheckHandler = useCallback(() => {
        setChecked(false)
        onUnchecked?.(microstepViewState)
    }, [setChecked, onUnchecked, microstepViewState])

    const onAddedHandler = useCallback(() => {
        onAdded?.(microstepViewState)
    }, [microstepViewState, onAdded])

    const openDialogHandler = useCallback(() => {
        setOpenDialog(true)
        setMicrostepViewState(MicrostepViewState.Dialog)
        onDialogOpen?.()
    }, [setOpenDialog, onDialogOpen])

    const closeDialogHandler = useCallback(() => {
        setOpenDialog(false)
        setMicrostepViewState(MicrostepViewState.Card)
        onDialogClose?.()
    }, [setOpenDialog, onDialogClose])

    const onRemoveHandler = useCallback(
        (removalReason: MicrostepRemovalReason) => {
            onRemoved?.(removalReason)
        },
        [onRemoved]
    )

    useEffect(() => {
        if (openOnMount) {
            openDialogHandler()
        }
    }, [openOnMount, openDialogHandler])

    useEffect(() => {
        setChecked(isChecked)
    }, [isChecked])

    return (
        <Box
            data-testid="microstep-card"
            width="100%"
            {...(fillSpace && {
                height: '100%'
            })}
        >
            <MicrostepCardData
                microstep={microstep}
                state={state}
                isChecked={checked}
                disabled={disabled}
                onChecked={onCheckHandler}
                onAdded={onAddedHandler}
                onOpenDialog={openDialogHandler}
                onUnchecked={onUncheckHandler}
                searchStr={searchStr}
                disableDialog={disableDialog}
                elevation={elevation}
                fillSpace={fillSpace}
                customTitle={customTitle}
                data-testid="microstep-card-data"
            />
            {openDialog && (
                <MicrostepDialog
                    isOpen={openDialog}
                    onClose={closeDialogHandler}
                    microstep={microstep}
                    state={state}
                    isChecked={checked}
                    onAdded={onAddedHandler}
                    onChecked={onCheckHandler}
                    onUnchecked={onUncheckHandler}
                    onRemoved={onRemoveHandler}
                    onShareClicked={() => onShareClicked?.()}
                    shareUrl={shareUrl}
                    disableRemove={disableRemove}
                />
            )}
        </Box>
    )
}

export default MicrostepCard
